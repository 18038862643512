import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCartContext } from '../context/CartContext';
import { useUserContext } from '../context/UserContext';
import styles from '../styles/header.module.css';
import NotificationsIcon from '@mui/icons-material/Notifications';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import Badge from '@mui/material/Badge';

export const Header: React.FC = () => {
    const { cart } = useCartContext();
    const { isAuthenticated, user } = useUserContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [notificationsCount, setNotificationsCount] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const location = useLocation();

  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const isActive = (path: string) => {
    return location.pathname === path ? styles.active : '';
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <button 
          className={styles.menuButton} 
          onClick={toggleMenu}
          aria-label={isMenuOpen ? 'Close Menu' : 'Open Menu'}
        >
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </button>

        <Link to="/" className={styles.logo} onClick={closeMenu}>
          PixelTrek
        </Link>

        <nav className={`${styles.navigation} ${isMenuOpen ? styles.navigationOpen : ''}`}>
          <Link 
            to="/" 
            className={`${styles.navLink} ${isActive('/')}`}
            onClick={closeMenu}
          >
            Home
          </Link>
          <Link 
            to="/catalog" 
            className={`${styles.navLink} ${isActive('/catalog')}`}
            onClick={closeMenu}
          >
            Catalog
          </Link>
          <Link 
            to="/contacts" 
            className={`${styles.navLink} ${isActive('/contacts')}`}
            onClick={closeMenu}
          >
            Contacts
          </Link>
          <Link 
            to="/delivery" 
            className={`${styles.navLink} ${isActive('/delivery')}`}
            onClick={closeMenu}
          >
            Delivery & Payment
          </Link>
          <Link 
            to="/custom-software" 
            className={`${styles.navLink} ${isActive('/custom-software')}`}
            onClick={closeMenu}
          >
            Custom Software
          </Link>
          <Link 
            to="/faq" 
            className={`${styles.navLink} ${isActive('/faq')}`}
            onClick={closeMenu}
          >
            FAQ
          </Link>
        </nav>

        <div className={styles.headerActions}>
          <Link to="/cart" className={styles.iconButton}>
            <Badge badgeContent={totalItems} color="error">
              <ShoppingCartIcon />
            </Badge>
          </Link>

          {isAuthenticated ? (
            <>
              {/* <Link to="/notifications" className={styles.iconButton}>
                <Badge badgeContent={notificationsCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </Link> */}
              <Link to="/profile" className={styles.profileButton}>
                <PersonIcon />
                <span className={styles.userName}>{user?.name || 'Profile'}</span>
              </Link>
            </>
          ) : (
            <Link to="/auth" className={styles.signInButton}>
              <PersonIcon />
              {!isMobile && <span>Sign In</span>}
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};