import React, { createContext, useState, useContext, useEffect } from 'react';
import { Product } from '../types/types';
import { ImageKey } from '../utils/imageLoader';


interface ProductContextType {
    products: Product[];
    addProduct: (product: Product) => void;
    isLoading: boolean;
  }
  
  const ProductContext = createContext<ProductContextType>({
    products: [],
    addProduct: () => {},
    isLoading: true
  });
  
  export const useProductContext = () => useContext(ProductContext);
  
  export const ProductProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState<Product[]>([
  
        {
            id: 1,
            priority: 100,
            name: "Enterprise CMS Platform",
            description: "Professional content management system for large projects with advanced security and scaling capabilities",
            price: 1500,
            category: "ready-solutions",
            imageUrl: 'ph1',
            features: [
              "Multi-user mode",
              "Advanced permissions system",
              "Integration API",
              "Built-in CDN",
              "DDoS protection"
            ]
          },
          {
            id: 2,
            priority: 0,
            name: "Premium Website Templates Pack",
            description: "Collection of professional responsive templates for various business types with source files and documentation",
            price: 400,
            category: "ready-solutions",
            imageUrl: 'ph2',
            features: [
              "50+ unique templates",
              "Responsive design",
              "SEO optimization",
              "High performance",
              "Regular updates"
            ]
          },
          {
            id: 3,
            priority: 0,
            name: "Advanced Landing Page Builder",
            description: "Professional landing page builder with A/B testing and analytics",
            price: 750,
            category: "ready-solutions",
            imageUrl: 'ph3',
            features: [
              "Drag-and-drop editor",
              "A/B testing",
              "CRM integration",
              "Conversion analytics",
              "Mobile optimization"
            ]
          },
          {
            id: 4,
            priority: 100,
            name: "E-commerce Platform Pro",
            description: "Comprehensive solution for creating and managing enterprise-level online stores",
            price: 1750,
            category: "ready-solutions",
            imageUrl: 'ph4',
            features: [
              "Multi-currency support",
              "Marketplace integration",
              "Loyalty system",
              "Marketing automation",
              "Sales analytics"
            ]
          },
          {
            id: 5,
            priority: 0,
            name: "WordPress Enterprise Plugin Suite",
            description: "Set of premium WordPress plugins with advanced functionality",
            price: 450,
            category: "ready-solutions",
            imageUrl: 'ph5',
            features: [
              "SEO optimization",
              "Security",
              "Caching",
              "System backup",
              "External services integration"
            ]
          },
      
          // Development and Integration
          {
            id: 6,
            priority: 0,
            name: "Enterprise Website Development",
            description: "Full-cycle corporate website development with unique design and integrations",
            price: 7500,
            category: "development",
            imageUrl: 'ph6',
            features: [
              "Unique design",
              "Comprehensive testing",
              "Technical support",
              "SEO optimization",
              "Staff training"
            ]
          },
          {
            id: 7,
            priority: 100,
            name: "Full CRM Integration Package",
            description: "Comprehensive CRM system integration with telephony and online payment systems",
            price: 2500,
            category: "development",
            imageUrl: 'ph7',
            features: [
              "CRM setup",
              "Telephony integration",
              "Payment system connection",
              "Process automation",
              "Employee training"
            ]
          },
          {
            id: 8,
            priority: 0,
            name: "Analytics Implementation Pro",
            description: "Professional setup of analytics and tracking systems",
            price: 1250,
            category: "development",
            imageUrl: 'ph8',
            features: [
              "Google Analytics 4",
              "Web analytics",
              "Goal setup",
              "E-commerce tracking",
              "Custom reports"
            ]
          },
          {
            id: 9,
            priority: 0,
            name: "Microservices Architecture",
            description: "Development and implementation of microservice architecture",
            price: 6500,
            category: "development",
            imageUrl: 'ph9',
            features: [
              "Scalable architecture",
              "CI/CD pipeline",
              "Monitoring",
              "Fault tolerance",
              "API documentation"
            ]
          },
          {
            id: 10,
            priority: 0,
            name: "Payment Systems Integration",
            description: "Comprehensive integration of payment systems and processing",
            price: 2000,
            category: "development",
            imageUrl: 'ph10',
            features: [
              "Multiple payment methods",
              "Secure processing",
              "Automatic reconciliation",
              "Refunds and chargebacks",
              "Reporting"
            ]
          },
      
          // Automation
          {
            id: 11,
            priority: 0,
            name: "Enterprise Telegram Bot",
            description: "Development of corporate Telegram bot with internal systems integration",
            price: 1250,
            category: "automation",
            imageUrl: 'ph11',
            features: [
              "CRM integration",
              "Automatic responses",
              "Usage analytics",
              "Control panel",
              "Extensible API"
            ]
          },
          {
            id: 12,
            priority: 0,
            name: "Website Chat Bot Pro",
            description: "Intelligent website chatbot with machine learning capabilities",
            price: 850,
            category: "automation",
            imageUrl: 'ph12',
            features: [
              "AI for request processing",
              "Multi-language support",
              "CRM integration",
              "Conversation analytics",
              "Customizable scenarios"
            ]
          },
          {
            id: 13,
            priority: 0,
            name: "Marketing Automation System",
            description: "System for automating marketing campaigns and communications",
            price: 1750,
            category: "automation",
            imageUrl: 'ph13',
            features: [
              "Email marketing",
              "SMS campaigns",
              "Push notifications",
              "A/B testing",
              "Audience segmentation"
            ]
          },
          {
            id: 14,
            priority: 0,
            name: "Business Process Automation",
            description: "Comprehensive automation of company business processes",
            price: 4000,
            category: "automation",
            imageUrl: 'ph14',
            features: [
              "Workflow automation",
              "Systems integration",
              "Reporting",
              "Monitoring",
              "Process optimization"
            ]
          },
          {
            id: 15,
            priority: 0,
            name: "Social Media Management Bot",
            description: "Automated social media management",
            price: 750,
            category: "automation",
            imageUrl: 'ph15',
            features: [
              "Content publishing",
              "Comment moderation",
              "Activity analytics",
              "Mention monitoring",
              "Auto-responses"
            ]
          },
      
          // Data Storage and Management Systems
          {
            id: 16,
            priority: 0,
            name: "Enterprise Database Solution",
            description: "Comprehensive solution for corporate data management",
            price: 5000,
            category: "data-systems",
            imageUrl: 'ph16',
            features: [
              "High performance",
              "Replication",
              "Redundancy",
              "Security",
              "24/7 support"
            ]
          },
          {
            id: 17,
            priority: 0,
            name: "Backup System Pro",
            description: "Professional data backup system",
            price: 2500,
            category: "data-systems",
            imageUrl: 'ph17',
            features: [
              "Incremental backup",
              "Encryption",
              "Versioning",
              "Automation",
              "Monitoring"
            ]
          },
          {
            id: 18,
            priority: 0,
            name: "Enterprise Cloud Storage",
            description: "Corporate cloud storage with advanced security",
            price: 3000,
            category: "data-systems",
            imageUrl: 'ph18',
            features: [
              "Encryption",
              "Access control",
              "Versioning",
              "Active Directory",
              "Audit"
            ]
          },
          {
            id: 19,
            priority: 0,
            name: "Document Management System",
            description: "Enterprise-level electronic document management system",
            price: 3500,
            category: "data-systems",
            imageUrl: 'ph19',
            features: [
              "Electronic document flow",
              "Approval workflow",
              "Full-text search",
              "ERP integration",
              "Digital signatures"
            ]
          },
          {
            id: 20,
            priority: 0,
            name: "Data Migration Suite",
            description: "Tools for data migration and transformation",
            price: 2000,
            category: "data-systems",
            imageUrl: 'ph20',
            features: [
              "Automatic migration",
              "Data validation",
              "Field mapping",
              "Reporting",
              "Format support"
            ]
          }
        ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500);
    
    return () => clearTimeout(timer);
        }, []);

    const addProduct = (product: Product) => {
        setProducts(prev => [...prev, { ...product, id: Date.now() }]);
    };

    return (
        <ProductContext.Provider value={{ products, addProduct, isLoading }}>
            {children}
        </ProductContext.Provider>
    );
};
      
      export default ProductProvider;