import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface User {
  id?: string;
  email?: string;
  name?: string;
  comment?: string;
  address?: string;
  terms_acceptance?: boolean;
  email_notifications?: boolean;
  order_updates?: boolean;
  promotions?: boolean;
  orders?: any;

}

export interface ProfileUpdateData {
    name?: string;
    address?: string;
    comment?: string;
    email_notifications?: boolean;
    order_updates?: boolean;
    promotions?: boolean;
}

interface UserContextType {
  user: User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  login: (email: string, code: string) => Promise<void>;
  logout: () => Promise<void>;
  requestEmailCode: (email: string) => Promise<void>;
  updateUserProfile: (data: ProfileUpdateData) => Promise<void>;
  acceptTerms: (email: string) => Promise<void>;
}

const UserContext = createContext<UserContextType>({
  user: null,
  isLoading: false,
  isAuthenticated: false,
  login: async () => {},
  logout: async () => {},
  requestEmailCode: async () => {},
  updateUserProfile: async () => {},
  acceptTerms: async () => {}
});

// Функции для работы с localStorage
const storage = {
  getUser: (): User | null => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  },
  
  setUser: (user: User | null) => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  },
  
  getToken: (): string | null => {
    return localStorage.getItem('token');
  },
  
  setToken: (token: string | null) => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  },
  
  clearSession: () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }
};

export const UserProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [user, setUser] = useState<User | null>(() => storage.getUser());
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Синхронизация состояния с localStorage
  useEffect(() => {
    storage.setUser(user);
  }, [user]);

  const login = async (email: string, code: string) => {
    setIsLoading(true);
    try {

    //   const response = await fetch('http://localhost:8000/api/auth/verify-code', {
      const response = await fetch('https://pixeltrek.store/api/auth/verify-code', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, code })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Authentication failed');
      }

      const data = await response.json();
      
      // Сохраняем токен и данные пользователя
      storage.setToken(data.access_token);

      const userData: User = {
        id: data.user_id || '1',
        email: data.email,
        name: data.name,
        address: data.address,
        comment: data.comment,
        terms_acceptance: data.terms_acceptance,
      };
      setUser(userData);
      return data
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };


  const logout = async () => {
    try {
      const token = storage.getToken();
      if (token) {
        // Опционально: уведомить сервер о выходе
        // await fetch('http://localhost:8000/api/auth/logout', {

        const response = await fetch('https://pixeltrek.store/api/auth/logout', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      }
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      storage.clearSession();
      setUser(null);
      navigate('/login');
    }
  };


  const requestEmailCode = async (email: string) => {
    try {
        // const response = await fetch('http://localhost:8000/api/auth/request-code', {

        const response = await fetch('https://pixeltrek.store/api/auth/request-code', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to send verification code');
      }
    } catch (error) {
      console.error('Request code error:', error);
      throw error;
    }
  };

  const updateUserProfile = async (data: ProfileUpdateData) => {
    const token = storage.getToken();
    try {
        const response = await fetch(`https://pixeltrek.store/api/users/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({data})
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message || 'Failed to update profile');
        }
        console.log(data)
        const userData: User = {
            id: user?.id,
            email: user?.email,
            name: data.name as string,
            address: data.address as string,
            comment: data.comment as string,
            email_notifications: data.email_notifications,
            order_updates: data.order_updates,
            promotions: data.promotions,
            terms_acceptance: user?.terms_acceptance
        };

        setUser(userData);

        return await response.json();
    } catch (error) {
        console.error('Update profile error:', error);
        throw error;
    }
}


  const acceptTerms = async (email: string) => {
        const token = storage.getToken();
        try {
            const response = await fetch('https://pixeltrek.store/api/auth/terms-acceptance', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email }),
            });

            if (response.status === 401) {
                // Перезапрашиваем код подтверждения
                throw new Error('Session expired. Please verify your email again.');
            }

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to accept terms');
              }
            const data = await response.json();

            const userData: User = {
                id: user?.id,
                email: user?.email,
                name: user?.name,
                address: user?.address,
                comment: user?.comment,
                terms_acceptance: data.terms_acceptance,
            };
            setUser(userData);
            return data
        } catch (error) {
            console.error('Failed to check terms acceptance:', error);

            storage.clearSession();
            setUser(null)
            return false;
        }
  };

  return (
    <UserContext.Provider 
      value={{
        user,
        isLoading,
        isAuthenticated: !!user,
        login,
        logout,
        requestEmailCode,
        updateUserProfile,
        acceptTerms
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);