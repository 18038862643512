import React, {Suspense, lazy} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProductProvider } from './context/ProductContext';
import { CartProvider } from './context/CartContext';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Loader } from './components/Loader';
import { ScrollToTop } from './components/ScrollToTop';
import { UserProvider } from './context/UserContext';

import styles from './/styles/electronic-store.module.css';


const PaymentPage = lazy(() =>
  import('./pages/PaymentPage').then(module => ({ default: module.PaymentPage }))
);
const HomePage = lazy(() => 
  import('./pages/HomePage').then(module => ({ default: module.HomePage }))
);
const DeliveryPage = lazy(() => 
  import('./pages/DeliveryPage').then(module => ({ default: module.DeliveryPage }))
);
const CartPage = lazy(() => 
  import('./pages/CartPage').then(module => ({ default: module.CartPage }))
);
const FaqPage = lazy(() => 
  import('./pages/FaqPage').then(module => ({ default: module.FaqPage }))
);
const ContactsPage = lazy(() => 
  import('./pages/ContactsPage').then(module => ({ default: module.ContactsPage }))
);
const CatalogPage = lazy(() => 
  import('./pages/CatalogPage').then(module => ({ default: module.CatalogPage }))
);
const CustomSoftwarePage = lazy(() => 
  import('./pages/CustomSoftwarePage').then(module => ({ default: module.CustomSoftwarePage }))
);
const PaymentSuccessPage = lazy(() => 
  import('./pages/PaymentSuccessPage').then(module => ({ default: module.PaymentSuccessPage }))
);
const PaymentErrorPage = lazy(() => 
  import('./pages/PaymentErrorPage').then(module => ({ default: module.PaymentErrorPage }))
);
const TermsOfServicePage = lazy(() => 
  import('./pages/TermsOfServicePage').then(module => ({ default: module.default }))
);
const PrivacyPolicyPage = lazy(() => 
  import('./pages/PrivacyPolicyPage').then(module => ({ default: module.default }))
);
const ProfilePage = lazy(() => 
  import('./pages/ProfilePage').then(module => ({ default: module.ProfilePage }))
);
const AuthPage = lazy(() => 
  import('./pages/AuthPage').then(module => ({ default: module.AuthPage }))
);

const App: React.FC = () => {
  return (
    <Router>
        <UserProvider>
            <ProductProvider>
                <CartProvider>
                <ScrollToTop />
                <div className={styles.app}>
                    <Header />
                    <main className={styles.main}>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/cart" element={<CartPage />} />
                        <Route path="/payment" element={<PaymentPage />} />
                        <Route path="/payment/success" element={<PaymentSuccessPage />} />
                        <Route path="/payment/error" element={<PaymentErrorPage />} />
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/auth" element={<AuthPage />} />
                        <Route path="/faq" element={<FaqPage />} />
                        <Route path="/contacts" element={<ContactsPage />} />
                        <Route path="/catalog" element={<CatalogPage />} />
                        <Route path="/delivery" element={<DeliveryPage />} />
                        <Route path="/custom-software" element={<CustomSoftwarePage />} />
                        <Route path="/terms" element={<TermsOfServicePage />} />
                        <Route path="/privacy" element={<PrivacyPolicyPage />} />
                        </Routes>
                    </Suspense>
                    </main>
                    <Footer />
                </div>
                </CartProvider>
            </ProductProvider>
        </UserProvider>
    </Router>
  );
};

export default App;