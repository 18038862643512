import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../styles/electronic-store.module.css';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';

export const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string, section?: string) => {
    navigate(path);

    if (section) {
      setTimeout(() => {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  };

  const downloadTerms = () => {
    // Путь к файлу terms.pdf в публичной папке
    const pdfPath = '/documents/terms_of_use.pdf';
    
    // Создаем временную ссылку для скачивания
    const link = document.createElement('a');
    link.href = pdfPath;
    link.download = 'Terms_of_Service.pdf';
    
    // Добавляем ссылку в документ, кликаем и удаляем
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPrivacyPolicy = () => {
    // Путь к файлу privacy.pdf в публичной папке
    const pdfPath = '/documents/privacy_policy.pdf';
    
    // Создаем временную ссылку для скачивания
    const link = document.createElement('a');
    link.href = pdfPath;
    link.download = 'Privacy_Policy.pdf';
    
    // Добавляем ссылку в документ, кликаем и удаляем
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerSection}>
          <h4>For Customers</h4>
          <ul>
            <li>
              <button 
                onClick={() => handleNavigation('/delivery', 'deliveryContainer')}
                className={styles.footerButton}
              >
                Delivery
              </button>
            </li>
            <li>
              <button 
                onClick={() => handleNavigation('/delivery', 'paymentContainer')}
                className={styles.footerButton}
              >
                Payment
              </button>
            </li>
            <li>
              <button 
                onClick={() => handleNavigation('/faq', 'faqContainer')}
                className={styles.footerButton}
              >
                FAQ
              </button>
            </li>
          </ul>
        </div>

        <div className={styles.footerSection}>
          <h4>Contacts</h4>
          <div className={styles.contactList}>
            <div className={styles.contactItem}>
              <PhoneIcon />
              <span>(+855) 87 550 787</span>
            </div>
            <div className={styles.contactItem}>
              <EmailIcon />
              <span>pixeltrek.ltd@gmail.com</span>
            </div>
          </div>
        </div>

        <div className={styles.footerSection}>
          <div className={styles.socialLinks}>
            <a href="#" className={styles.socialIcon}><TelegramIcon /></a>
            <a href="#" className={styles.socialIcon}><WhatsAppIcon /></a>
            <a href="#" className={styles.socialIcon}><InstagramIcon /></a>
          </div>
        </div>
      </div>

      <div className={styles.footerBottom}>
        <p>&copy; 2025 Pixeltrek Tech Co. All rights reserved.</p>
        <div className={styles.footerLinks}>
            <div onClick={downloadPrivacyPolicy} className={styles.clickable_div}>Privacy Policy</div>
            <div onClick={downloadTerms} className={styles.clickable_div}>Terms of Service</div>
          {/* <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link> */}
        </div>
      </div>
    </footer>
  );
};